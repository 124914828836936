@font-face {
font-family: '__ploni_4100ea';
src: url(/_next/static/media/6d3a656021f89776-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
}

@font-face {
font-family: '__ploni_4100ea';
src: url(/_next/static/media/e4ae72bcab6931a8-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__ploni_4100ea';
src: url(/_next/static/media/5deff142c8aa2d78-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: '__ploni_4100ea';
src: url(/_next/static/media/c8193e30aa7197d9-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: '__ploni_4100ea';
src: url(/_next/static/media/51b968a18557b4fe-s.p.otf) format('opentype');
font-display: swap;
font-weight: 900;
}@font-face {font-family: '__ploni_Fallback_4100ea';src: local("Arial");ascent-override: 89.80%;descent-override: 32.72%;line-gap-override: 28.81%;size-adjust: 97.13%
}.__className_4100ea {font-family: '__ploni_4100ea', '__ploni_Fallback_4100ea'
}.__variable_4100ea {--font-ploni: '__ploni_4100ea', '__ploni_Fallback_4100ea'
}

